.home {
    min-height: 90vh;
    position: relative;
    background-size: cover;
    width: 100%;
    opacity: 0.85;
}

.home .container {
    overflow: hidden;
}

.home h1 {
    color: white;
    position: absolute;
    bottom: 5rem;
    padding: 1rem;
    left: 0;
    right: 0;
    z-index: 2;
    font-family: 'Cinzel', serif;
}

.home .home-button {
    position: absolute;
    bottom: 1rem;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 1.5rem;
    padding-bottom: 1rem;
    margin-top: 1rem;
    left: 50%; 
    transform: translateX(-50%);
    right: 0;
    z-index: 2;
    font-family: 'Cinzel', serif;
    background-color: white;
    color: black;
    border: none;
    max-width: 300px;

}

.home .gallery-text {
    font-family: 'Cinzel', serif;
    font-size: 1.25rem;
    text-align: center;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .home {
        min-height: 60vh;
    }

    .home h1 {
        font-size: 1rem;
        bottom: 1.75rem;
        padding: 0.5rem;
    }

    .home .home-button {
    bottom: .5rem;
    padding-left: .5rem;
    padding-right: .5rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    margin-top: .5rem;
    max-width: 200px;
    }
    .home .gallery-text {
        font-size: .65rem;
        text-align: center;
    }
}

@media (max-width: 767px) {
    .home {
        min-height: 50vh;
    }

    .home h1 {
        font-size: 1rem;
        bottom: 1.75rem;
        padding: 0.5rem;
    }

    .home .home-button {
    bottom: .5rem;
    padding-left: .5rem;
    padding-right: .5rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    margin-top: .5rem;
    max-width: 200px;
    }
    .home .gallery-text {
        font-size: .65rem;
        text-align: center;
    }
    
}

@media only screen and (max-width: 700px) {
    .home {
        min-height: 40vh;
    }
}

@media only screen and (max-width: 550px) {
    .home {
        min-height: 37vh;
    }
}

@media only screen and (max-width: 488px) {
    .home {
        min-height: 35vh;
    }

    .home .gallery-text {
        font-size: .5rem;
    }

    .home h1 {
        font-size: .7rem;
    }

    .home .home-button {
        max-width: 100px;
    }
}

@media only screen and (max-width: 414px) {
    .home {
        min-height: 30vh;
    }

    .home .gallery-text {
        font-size: .5rem;
    }

    .home h1 {
        font-size: .7rem;
    }

    .home .home-button {
        max-width: 100px;
    }
}