.gallery-content-links {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 2rem;
}

.galleryLink {
    text-decoration: none;
    color: inherit;
    padding: 1rem;
    font-size: 1.5rem;
    font-family: 'Cinzel', serif;
}